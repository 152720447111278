.not-acknowledged {
  -webkit-animation-name: just-acknowledged-item-animation !important;
  animation-name: just-acknowledged-item-animation !important;
  -webkit-animation-duration: 5s !important;
  animation-duration: 5s !important;
  -webkit-animation-direction: alternate !important;
  animation-direction: alternate !important;
  -webkit-animation-iteration-count: 1 !important;
  animation-iteration-count: 1 !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
  border-radius: 2px;
}

.service-comment {
  padding: 5px;
  margin: -5px;

  .ant-comment-inner {
    padding: 15px 0 5px 0;
  }
}

.mention {
  font-weight: bold;
  color: #0064cf;
  cursor: pointer;

  &:hover {
    color: #0055bb;
  }
}
