.dashboard-widgets-wrapper {
  margin: 10px 10px 45px 10px;

  .ant-collapse {
    margin-bottom: 10px;
  }

  .ant-collapse-header {
    font-size: 16px;
    font-weight: 500;
  }

  .ant-collapse-content-box {
    padding: 0 10px;
  }
}
