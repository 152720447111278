.avatar {
  float: left;
  display: inline-block;
  margin-right: 5px;
}

.value {
  float: left;
  display: inline-block;
  position: relative;
  top: -3px;
}

.subtitle {
  display: inline-block;
  color: #999;
}
