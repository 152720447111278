.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.ant-form-item {
  margin-bottom: 5px;
}

.ant-modal-header .ant-modal-title {
  font-weight: 700;
}

.ant-modal-body {
  padding-top: 10px;
}

.item-label {
  position: relative;
  margin-right: 10px;
  font-weight: bold;
}

.ant-form-item-label {
  white-space: normal;
}

.ant-form-item-control,
.ant-form-item-label {
  line-height: 30px;
}

.scrollable-form {
  height: 76vh;
  overflow-y: auto;
  overflow-x: hidden;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollable-form::-webkit-scrollbar {
  width: 0 !important;
}

.form-actions {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #e8e8e8;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;
}

.data-modal .ant-modal-body {
  padding-bottom: 5px;
}

.contact-info p {
  margin-bottom: 3px;
}

.contact-info p:last-child {
  margin-bottom: 0;
}

.service-progress {
  border-radius: 2px;
  margin: 5px 0 15px 0;
  padding: 20px 20px 5px 20px;
  background: #f9f9f9;
  border: 1px solid #e8e8e8;
}

.page-content .form-actions {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
}

.chrome-picker {
  box-shadow: none !important;
  border: 1px solid #ddd !important;
}

.ql-picker-label {
  line-height: 18px !important;
}

.ql-snow .ql-picker {
  position: relative;
  top: -3px;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  margin-top: 2px;
}

.ant-comment-content {
  padding: 10px;
  background: #f9f9f9;
  border-radius: 2px;
  position: relative;
  border: 1px solid #e8e8e8;
}

.ant-comment-content:before {
  content: ' ';
  position: absolute;
  display: inline-block;
  height: 15px;
  width: 15px;
  background: #f9f9f9;
  margin: 0 auto;
  transform: rotate(45deg);
  border-radius: 0;
  margin-top: -22px;
  left: -7px;
  top: 40px;
  border-left: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.ant-comment .ant-comment-avatar {
  position: relative;
  top: 13px;
}

/** Legacy forms **/
.ant-legacy-form-item-control {
  line-height: 32px !important;
}

.ant-legacy-form-item-label {
  line-height: 32px !important;
  font-weight: 500;
}

.ant-legacy-form-item {
  margin-bottom: 5px !important;
}

.ant-legacy-form-vertical .ant-legacy-form-item {
  padding-bottom: 4px !important;
}

.ant-legacy-form-vertical .ant-legacy-form-item-label,
.ant-col-24.ant-legacy-form-item-label,
.ant-col-xl-24.ant-legacy-form-item-label {
  padding-bottom: 2px !important;
}

/** New forms **/
.ant-form-item-label {
  line-height: 32px !important;
  font-weight: 500;
}

/** Code blocks from quill **/
pre.ql-syntax {
  padding: 10px;
  border-radius: 4px;
  background: #272822;
  color: #f8f8f2;
  font-size: 10px;
  line-height: 16px;
}
