@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';

@background-color-base: #f9f9f9;
@primary-color: #0064cf;
@info-color: @primary-color;
@success-color: rgb(116, 200, 84);
@processing-color: #0064cf;
@error-color: #f5222d;
@highlight-color: @error-color;
@warning-color: #faad14;
@input-disabled-bg: #e4e4e4;
@item-hover-bg: #eaeaea;
@light-gray-color: #f9f9f9;
@gray-color: #fbfbfb;

@picker-bg: linear-gradient(@gray-color, @light-gray-color);

.disabled() {
  color: @input-disabled-color;
  background-color: @input-disabled-bg;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    .hover(@input-border-color);
  }
}

.input() {
  background: linear-gradient(@gray-color, @light-gray-color);
}

.select-selector() {
  background: linear-gradient(@gray-color, @light-gray-color);
}

.@{select-prefix-cls} {
  &-dropdown {
    background: linear-gradient(@gray-color, @light-gray-color);
  }
}

@select-selection-item-bg: #fff;

.@{descriptions-prefix-cls} {
  &-item-label {
    font-weight: 500;
  }
}

.ant-btn-success {
  background-color: @success-color;
  color: #fff;
  border: 1px solid @success-color;

  &:hover,
  &:active,
  &:focus {
    background-color: darken(@success-color, 10%);
    color: #fff;
    border: 1px solid darken(@success-color, 10%);
  }
}

@descriptions-item-padding-bottom: 8px;

.ant-comment-content-detail {
  p {
    margin-bottom: 15px;
  }
}

.ant-collapse-header > div {
  display: inline-block;
}

.ant-layout-header .ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent !important;
}
