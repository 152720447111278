.ant-timeline-item-right
  .ant-timeline-item-content
  .ant-comment-content-author:after {
  display: block;
  clear: both;
  content: ' ';
}

.ant-timeline-item-right
  .ant-timeline-item-content
  .ant-comment-content-detail {
  width: 100%;
  float: right;
}

.ant-comment-content-detail {
  img {
    max-width: 100%;
  }
}

.ant-timeline-item-content .ant-comment {
  background: #e6f7ff;
  border-radius: 2px;
}

.ant-timeline-item-content .ant-comment.customer-note {
  background: #f5f5f5;
}

.ant-timeline-item-content .ant-comment .ant-comment-content {
  background: transparent !important;
  padding: 0;
  border: none;
}

.ant-timeline-item-content .ant-comment .ant-comment-avatar {
  top: 0 !important;
}

.ant-timeline-item-content .ant-comment .ant-comment-content:before {
  background: transparent;
  border: none;
  content: '';
}

.ant-timeline-item-right .ant-comment {
  padding-right: 15px;
}

.ant-timeline-item-right .ant-comment-content-author-time {
  padding-right: 0;
}

.ant-timeline-item-left .ant-comment,
.ant-timeline-item-right .ant-comment {
  position: relative;
  top: -5px;
}

.ant-timeline-item-left .ant-comment:after,
.ant-timeline-item-left .ant-comment:before {
  right: 100%;
  top: 17px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ant-timeline-item-left .ant-comment:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: #e6f7ff;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item-left .ant-comment:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: #e6f7ff;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item-right .ant-comment:after,
.ant-timeline-item-right .ant-comment:before {
  left: 100%;
  top: 17px;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ant-timeline-item-right .ant-comment:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #e6f7ff;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item-right .ant-comment:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #e6f7ff;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item-right .ant-comment.customer-note:after {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #f5f5f5;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item-right .ant-comment.customer-note:before {
  border-color: rgba(136, 183, 213, 0);
  border-left-color: #f5f5f5;
  border-width: 6px;
  margin-top: -6px;
}

.ant-timeline-item .comment-author-with-avatar {
  position: relative;
}

.ant-timeline-item .ant-avatar {
  position: relative;
  top: -2px;
  margin-left: 12px;
}

.ant-timeline.ant-timeline-alternate
  .ant-timeline-item-right
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-right
  .ant-timeline-item-right
  .ant-timeline-item-content {
  text-align: left;
}
