.userInfoMenuItem {
  height: 80px !important;
  border-bottom: 1px solid #ddd;

  &:hover {
    background: #fff !important;
  }

  .fullNameWrapper {
    color: #333 !important;
    font-weight: 500;
  }

  .permissionWrapper {
    color: #6d6d6d !important;
    font-size: 12px;
    line-height: 16px;

    :global {
      .ant-tag {
        margin-left: 15px;
        margin-right: 0;
      }
    }
  }
}
