tr.added {
  -webkit-animation-name: added-row-animation !important;
  animation-name: added-row-animation !important;
  -webkit-animation-duration: 3s !important;
  animation-duration: 3s !important;
  -webkit-animation-direction: alternate !important;
  animation-direction: alternate !important;
  -webkit-animation-iteration-count: 1 !important;
  animation-iteration-count: 1 !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}

tr.updated {
  -webkit-animation-name: added-row-animation !important;
  animation-name: added-row-animation !important;
  -webkit-animation-duration: 3s !important;
  animation-duration: 3s !important;
  -webkit-animation-direction: alternate !important;
  animation-direction: alternate !important;
  -webkit-animation-iteration-count: 1 !important;
  animation-iteration-count: 1 !important;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}

.clickable {
  cursor: pointer;
}

tr.selected {
  background: #f0f6ff;
}

tr.unread {
  background: #fff0dd;
}

.content-wrapper.no-padding-and-margin {
  margin: 0 !important;
}

.content-wrapper.no-padding-and-margin .inner-table-wrapper {
  margin: 0 !important;
}

/**
.content-wrapper .inner-table-wrapper {
    margin: 0 0 10px 0 !important;
}
**/

.content-wrapper.no-padding-and-margin .ant-page-header {
  padding: 0 0 10px 0;
}

.content-wrapper.no-padding-and-margin .page-header {
  padding: 10px 0 10px 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.content-wrapper.no-padding-and-margin
.page-header
.ant-page-header-heading-title {
  font-size: 14px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.datatable-header {
  margin-bottom: 20px;
  font-weight: bold;
}

.datatable-header h1 {
  font-size: 20px;
  margin: 0;
  font-weight: 700;
}

.ant-page-header-ghost {
  padding-bottom: 0 !important;
}

.inner-table-wrapper {
  background: #fff;
  border-radius: 2px;
  margin: 0 15px 50px 15px;
  border: 1px solid #ddd;
  position: relative;
}

.inner-table-wrapper.no-border {
  border: 0 !important;
}

.ant-table-column-title {
  font-weight: 700;
}

.quick-search {
  float: left;
  position: relative;
}

.quick-search-input {
  width: 258px;
  float: left;
  margin-right: 5px;
}

.quick-search-reset {
  width: 90px;
  float: left;
}

.search-form-wrapper {
  padding: 10px;
}

.service-progress-table .ant-steps-item-title::after {
  left: 0 !important;
}

.service-progress-table
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical)
.ant-steps-item {
  margin-right: 0;
}

.service-progress-table .ant-steps-small .ant-steps-item-icon {
  margin-right: 0;
}

.service-progress-table .ant-steps-small .ant-steps-item-title {
  padding-right: 5px;
}

.service-progress-table .ant-steps-label-vertical .ant-steps-item-tail {
  margin: 0;
}

.service-progress-table .ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: 0;
}

.service-progress-table
.service-progress-table
.ant-steps-small
.ant-steps-item-icon {
  margin: 0;
}

.service-progress-table .ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 0;
}

.service-progress-table .ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.service-progress-table .ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 6px);
  height: 3px;
  margin-left: 6px;
}

.service-progress-table .service-progress-steps {
  padding: 5px;
  position: relative;
  left: -5px;
  height: 5px;
}

.hide-table-border {
  padding: 0;

  table {
    border: 0 !important;
  }

  .ant-table-container {
    border: 0 !important;
  }

  .ant-pagination {
    padding: 0 10px;
  }
}

.show-table-border {
  padding: 0 15px 15px 15px;
}

.hide-table-border .ant-table-bordered.ant-table-empty .ant-table-placeholder {
  border-left: 0 !important;
  border-right: 0 !important;
}

.ant-table-column-title {
  word-wrap: normal !important;
  word-break: normal !important;
}

.data-modal-subtitle {
  font-size: 13px;
  font-weight: 500;
  margin-left: 10px;
}

.bulk-actions-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  background: #fff;
  border-top: 1px solid #ddd;
  box-sizing: border-box;
  padding: 10px;
  text-align: right;
  box-shadow: 0 0 10px 0px #d2d2d2;
}

.row-warning {
  background: #fff3de;
}

.row-danger {
  background: #ffd5d5;
}

.ant-empty-normal {
  margin: 5px 0;
}

.ant-page-header-heading-extra {
  padding-bottom: 10px;
}

.ant-table-content::-webkit-scrollbar {
  width: 2px !important;
  height: 5px !important;
}

.ant-table-content::-webkit-scrollbar-button {
  display: none !important;
}

.ant-table-content::-webkit-scrollbar-track-piece {
  background: #e8e8e8 !important;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background: #f9f9f9 !important;
}

.ant-table-content {
  overflow: hidden;
  overflow-x: auto !important;
  scrollbar-color: #e8e8e8 #f9f9f9;
  scrollbar-width: thin;
}

.ant-table-tbody tr td .ant-tag {
  margin-right: 0 !important;
}

.ant-page-header-heading-extra > * {
  margin-left: 5px;
}

.ant-table-expanded-row > td {
  background: #fff !important;
}

.ant-table.ant-table-middle
.ant-table-tbody
.ant-table-wrapper:only-child
.ant-table {
  margin: 0 !important;
}

.ant-table-row-expand-icon-cell {
  padding: 12px 15px !important;
}

.ant-table-cell-fix-right {
  padding: 6px 4px !important;
}

.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 6px 8px;
}

.ant-table-expanded-row > .ant-table-cell {
  background: #efefef !important;
  box-shadow: 0 0 25px 0 inset #d0d0d0;
}
