.ant-layout-header {
  background: #0064cf;
  padding: 0 25px;
}

.ant-menu.ant-menu-dark {
  background: #0064cf;
}

.ant-layout-sider {
  border-right: 1px solid #ddd;
}

.ant-layout-sider-trigger {
  border-right: 1px solid #ddd;
}

.ant-menu-dark .ant-menu-sub .ant-menu-item {
  background: #ffffff !important;
}

.ant-menu-dark .ant-menu-sub {
  background: #ffffff !important;
}

.ant-menu-dark .ant-menu-sub a {
  color: #333 !important;
}

.ant-menu-dark .ant-menu-sub a:hover {
  color: #505050 !important;
}

.header .ant-menu-horizontal {
  line-height: 64px !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  margin: 0 !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.ant-layout-sider .ant-menu-light .ant-menu-sub {
  background: #f5f5f5;
  box-shadow: inset 0 0 5px -2px #919191;
}

.ant-layout-sider .ant-menu-light .ant-menu-sub .ant-menu-item {
}

.ant-layout-sider .ant-menu-light .ant-menu-sub .ant-menu-sub {
  box-shadow: inset 0 0 5px -2px #919191;
  background: #f9f9f9;
}

.ant-layout-sider .ant-menu-item-selected {
  background: #d3e0ec5e !important;;
}

.ant-layout-sider .ant-menu-light .ant-menu-sub .ant-menu-sub .ant-menu-item {
  padding-left: 56px !important;
}

.ant-layout-sider .ant-menu-light .ant-menu-sub a {
  color: #333 !important;;
}

.ant-layout-sider .ant-menu-light .ant-menu-sub a:hover {
  color: #505050 !important;;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0;
}

.ant-menu-item,
.ant-menu-submenu-title {
  text-transform: uppercase;
  font-weight: 400;
}

.customer-ui .ant-menu-vertical .ant-menu-item,
.customer-ui .ant-menu-vertical-left .ant-menu-item,
.customer-ui .ant-menu-vertical-right .ant-menu-item,
.customer-ui .ant-menu-inline .ant-menu-item,
.customer-ui .ant-menu-vertical .ant-menu-submenu-title,
.customer-ui .ant-menu-vertical-left .ant-menu-submenu-title,
.customer-ui .ant-menu-vertical-right .ant-menu-submenu-title,
.customer-ui .ant-menu-inline .ant-menu-submenu-title {
  font-size: 16px !important;
}

.ant-menu-vertical .ant-menu-submenu-title {
  color: #000 !important;
}

.ant-menu-vertical .ant-menu-submenu-title:hover,
.ant-menu-vertical .ant-menu-item:hover {
  background: #e0f2ff !important;
}

.ant-menu-submenu > .ant-menu {
  border-radius: 0 !important;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  width: 20px;
}

.sider-company-logo {
  position: absolute;
  bottom: 30px;
  max-width: 100%;
  width: 100%;
  text-align: center;
}

.sider-company-logo img {
  max-width: 90%;
  width: 120px;
  height: auto;
  display: block;
  margin: 0 auto;
}

.diff-header {
  padding: 10px;
  background: #fbfbfb;
  border: 1px solid #ccc;
  border-bottom: 0;
  border-radius: 3px 3px 0 0;
}

.d2h-file-header {
  display: none;
}

.ant-menu-horizontal .ant-menu-submenu-title {
  color: #fff !important;
  font-weight: 400;
}

.ant-layout-header .ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: #0056b3;
}

.ant-layout-header .ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #0056b3;
}

.sidebar .ant-menu-inline-collapsed .anticon {
  display: block;
  margin: 0 auto;
  width: auto !important;
}

.ant-tooltip-inner a {
  color: #fff !important;
}

.ant-tooltip-inner .anticon {
  margin-right: 5px;
  position: relative;
  top: -1px;
}
