.releaseNotesModal {
  .releaseNotesModalTitle {
  }

  :global {
    .ant-modal-header {
      background: #0064cf;

      .ant-modal-title {
        color: #fff;
        font-size: 24px;
        font-weight: normal;
      }
    }

    .logo {
      display: block;
      float: none;
      margin-bottom: 20px;
    }

    .ant-modal-close-x {
      color: #fff;
    }
  }
}
