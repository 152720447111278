@font-face {
  font-family: ProximaNova;
  src: url('../fonts/ProximaNova-Reg.ttf');
}

@font-face {
  font-family: ProximaNova;
  font-weight: 600;
  src: url('../fonts/ProximaNova-Bold.ttf');
}

@font-face {
  font-family: ProximaNova;
  font-weight: 500;
  src: url('../fonts/ProximaNova-Sbold.ttf');
}

:root{
  --adm-font-family: ProximaNova, Arial, sans-serif !important;
}

body {
  font-family: ProximaNova, Arial, sans-serif;
  font-size: 16px;
  background: #f0f0f7;
}

body.splash-page {
  background: url('../images/netportal-entry-bg.jpg') no-repeat;
  background-size: cover;
}

.clearfix {
  clear: both;
  height: 0;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.logo {
  color: #fff;
}

.bold {
  font-weight: bold;
}

.logo {
  background: url('../images/netportal-logo-white.svg') transparent center
    center no-repeat;
  height: 64px;
  width: 150px;
  float: left;
}

.logo-black {
  background: url('../images/netportal-logo-black.svg') transparent center
    center no-repeat;
  height: 32px;
  width: 150px;
  margin-right: 20px;
  float: left;
}

.ant-layout-footer {
  padding: 25px 17px;
}

.app-version-tag {
  font-weight: bold;
  font-size: 12px;
}

.ant-layout-footer {
  font-size: 12px;
  color: #929292;
}

@keyframes added-row-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}

@-webkit-keyframes added-row-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}

@-moz-keyframes added-row-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}

@keyframes just-acknowledged-item-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}

@-webkit-keyframes just-acknowledged-item-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}

@-moz-keyframes just-acknowledged-item-animation {
  0% {
    background-color: #b0ffb3;
  }
  100% {
    background-color: #ffffff;
  }
}
