.msg {
    text-align: center;
    position: fixed;
    width: 100%;
    height: 40px;
    z-index: 100;
    vertical-align: auto;
    padding-top: 7px;
    font-size: 16px;
}
.msgInfo {
    .msg;
    background-color: #00BFFF;
    color: white;
}
.msgWarning {
    .msg;
    background-color: #FFDC00;
    color: black;
}
.msgError {
    .msg;
    background-color: #FF4136;
    color: white;
}
.msgAbsent {
    display: none;
}
.blockStart {
    width: 40px;
    display: inline-block;
}
.btnClose {
    width: 40px;
    display: inline-block;
    cursor: pointer;
}
.msgContainer {
    display: inline-block;
    width: calc(100% - 80px);
}
