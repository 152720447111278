.sim-card-usage-progress {
  .ant-progress-text {
    display: block;
    margin: 0 auto;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  .usage-percent {
    display: inline-block;
    margin-left: 20px;
  }

  .ant-progress-outer {
    margin-right: 0;
    padding-right: 0;
  }
}
