.draggable {
  cursor: pointer;
  opacity: 1;
  transition: all 500ms;
}

.draggable:hover {
  box-shadow: 0 0 25px 0 #ccc;
}

.draggable.dragging {
  opacity: 0.1;
}

.droppable-area-alert {
  padding: 10px;
  border-radius: 2px;
  border: 2px dashed #ccc;
  text-align: center;
}

.droppable-area-text {
  font-size: 16px;
  margin-bottom: 15px;
}
