code {
  font-family: 'Courier New', monospace;
}

.affected-components-list {
  font-size: 14px;
}

.ant-popover-inner-content {
  white-space: pre-wrap !important;
}
