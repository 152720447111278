.auth-wrapper {
  .login-form-wrapper {
    width: 350px;
    position: absolute;
    top: 50%;
    height: 150px;
    left: 50%;
    margin-left: -175px;
    margin-top: -100px;
    padding: 15px;
    text-align: center;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 15px 0 #151515;

    .ant-btn {
      font-weight: 500;
    }
  }

  .netportal-auth-logo {
    background: url('../../images/netportal-logo-blue.svg') no-repeat;
    width: 250px;
    display: block;
    height: 60px;
    margin: 0 auto 20px auto;
  }
}
