.page-header {
  font-weight: bold;
  padding: 11px 15px 0 15px;
  background: #fff;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.page-header .ant-page-header-heading-title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
  text-transform: uppercase;
}

.page-header .ant-page-header {
  padding: 0;
}

.page-content {
  padding: 15px;
  background: #fff;
  margin: 0 15px 50px 15px;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.top-content-wrapper {
  min-height: calc(100vh - 139px);
}

.footer {
  height: 30px;
  background: #f5f5f5;
  border-top: 1px solid #ddd;
  color: #797979;
  padding-top: 7px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
}

.content-wrapper {
  overflow: initial;
}

.access-denied-page-wrapper {
  margin: 40px auto;
  max-width: 1200px;
  padding: 10px;
}

.access-denied-page-wrapper .access-denied-image {
  width: 227px;
  height: 269px;
  max-width: 100%;
  background: url('../images/403.svg') center center no-repeat;
}

.access-denied-page-wrapper .access-denied-text {
  padding-top: 50px;
  font-size: 14px;
  margin-bottom: 30px;
}

.access-denied-page-wrapper .logout-link {
  font-size: 14px;
  display: inline-block;
  color: #909090;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.text-center {
  text-align: center;
}

.sidebar {
  position: fixed;
  height: calc(100vh - 48px - 48px);
  left: 0;
  top: 64px;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  width: 2px;
}

.sidebar::-webkit-scrollbar-button {
  display: none;
}

.sidebar::-webkit-scrollbar-track-piece {
  background: #f9f9f9;
}

.sidebar::-webkit-scrollbar-thumb {
  background: #e8e8e8;
}

.sidebar {
  scrollbar-color: #e8e8e8 #f9f9f9;
  scrollbar-width: thin;
}

.ant-layout {
  background: #f0f0f7 !important;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-10 {
  margin-bottom: 10px;
}
